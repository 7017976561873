import apiClient from "./axiosInstance"

export const TAfindUser = async (query: any) => {
  const response = await apiClient.get(`/admin/user/getuser?${query}`, {})

  if (response.status === 200) {
    return response.data
  } else {
    throw new Error("Find User failed")
  }
}

export const TAfindUsername = async (data: any) => {
  const response = await apiClient.get(`/admin/user/getusername?username=${data}`, {})

  if (response.status === 200) {
    return response.data
  } else {
    throw new Error("Find User failed")
  }
}

export const TAfindCountry = async (data: any) => {
  const response = await apiClient.get(`/admin/user/getcountry?country=${data}`, {})

  if (response.status === 200) {
    return response.data
  } else {
    throw new Error("Find User failed")
  }
}

export const TAfindCity = async (data: any) => {
  const response = await apiClient.get(`/admin/user/getcity?city=${data}`, {})

  if (response.status === 200) {
    return response.data
  } else {
    throw new Error("Find User failed")
  }
}

export const TAfindJob = async (data: any) => {
  const response = await apiClient.get(`/admin/user/getjob?job=${data}`, {})

  if (response.status === 200) {
    return response.data
  } else {
    throw new Error("Find User failed")
  }
}

export const TAfindHobbies = async (data: any) => {
  const response = await apiClient.get(`/admin/user/gethobbies?hobbies=${data}`, {})

  if (response.status === 200) {
    return response.data
  } else {
    throw new Error("Find User failed")
  }
}

export const TAfindAllUser = async (
  page: number,
  perPage: number,
  params: any,
  sortBy: string,
  sortOrder: boolean,
) => {
  const response = await apiClient.get(
    `/admin/user/getall?${params}&page=${page}&perPage=${perPage}&sortBy=${sortBy}&sortOrder=${sortOrder}`,
    {},
  )
  if (response.status === 200) {
    return response.data
  } else {
    throw new Error("Find User failed")
  }
}

export const TAfindApprovalUser = async (params: any) => {
  const response = await apiClient.get(`/admin/user/getverificationprofiles?${params}`, {})

  if (response.status === 200) {
    return response.data
  } else {
    throw new Error("Find Approval Users failed")
  }
}

export const TAfindAllApprovalUser = async (
  page: number,
  perPage: number,
  params: any,
  sortBy: string,
  sortOrder: boolean,
) => {
  const response = await apiClient.get(
    `/admin/user/getallverificationprofiles?${params}&page=${page}&perPage=${perPage}&sortBy=${sortBy}&sortOrder=${sortOrder}`,
    {},
  )
  if (response.status === 200) {
    return response.data
  } else {
    throw new Error("Find User failed")
  }
}

export const TAverifyUser = async (id: string, status: string) => {
  const response = await apiClient.post(
    `/admin/user/verificateuser/${id}/${status}`,
    {},
    {
      headers: {
        "Content-Type": "application/json",
      },
    },
  )

  if (response.status === 200) {
    return response.data
  }
}

export const TAapprovedUser = async (
  params: any,
  page: number,
  perPage: number,
  sortBy: string,
  sortOrder: boolean,
) => {
  const response = await apiClient.get(
    `/admin/user/getverifiedprofiles?${params}&page=${page}&perPage=${perPage}&sortBy=${sortBy}&sortOrder=${sortOrder}`,
    {},
  )

  if (response.status === 200) {
    return response.data
  } else {
    throw new Error("Find Approved User failed")
  }
}

export const TAfindUserById = async (id: any) => {
  const response = await apiClient.get(`/admin/user/getuser/${id}`, {})

  if (response.status === 200) {
    return response.data
  } else {
    throw new Error("Find User failed")
  }
}

export const TArecoverAccount = async (id: string, status: string) => {
  const response = await apiClient.post(
    `/admin/user/recoveraccount/${id}/${status}`,
    {},
    {
      headers: {
        "Content-Type": "application/json",
      },
    },
  )

  if (response.status === 200) {
    return response.data
  }
}

export const TAchangePhone = async (id: string, phone: string) => {
  const response = await apiClient.post(
    `/admin/user/changephone/${id}/${phone}`,
    {},
    {
      headers: {
        "Content-Type": "application/json",
      },
    },
  )

  if (response.status === 200) {
    return response.data
  }
}

export const TAremoveVerification = async (id: string) => {
  const response = await apiClient.put(`/admin/user/removeverification/${id}`, {})

  if (response.status === 200) {
    return response.data
  } else {
    throw new Error("Find Remove Verification failed")
  }
}

export const TAfindInstaId = async (username: string) => {
  const response = await apiClient.post("/admin/user/getinstaid/", { username }, {})

  if (response.status === 200) {
    return response.data
  } else {
    throw new Error("Find User Tagged Posts failed")
  }
}

export const TAfindTaggedPosts = async (user_id: string) => {
  const response = await apiClient.post("/admin/user/getusertags/", { user_id }, {})

  if (response.status === 200) {
    return response.data
  } else {
    throw new Error("Find User Tagged Posts failed")
  }
}

export const TAupdateInsta = async (username: string, _id: string) => {
  const response = await apiClient.post("/admin/user/updateinsta", {
    username,
    _id,
  })

  if (response.status === 200) {
    return response.data
  } else {
    throw new Error("Find User failed")
  }
}

export const TAupdateTiktok = async (username: string, _id: string) => {
  const response = await apiClient.post("/admin/user/updatetiktok", {
    username,
    _id,
  })

  if (response.status === 200) {
    return response.data
  } else {
    throw new Error("Find User failed")
  }
}

export const TAuserIsSpam = async (_id: string, status: boolean) => {
  const response = await apiClient.post(`/admin/user/is_spam/${_id}`, {
    status,
  })

  if (response.status === 200) {
    return response.data
  } else {
    throw new Error("Find User failed")
  }
}

export const TAuserUnvisible = async (_id: string, status: boolean) => {
  const response = await apiClient.post(`/admin/user/unvisible/${_id}`, {
    status,
  })

  if (response.status === 200) {
    return response.data
  } else {
    throw new Error("Find User failed")
  }
}

export const TAusersSpamAndUnvisible = async (
  params: any,
  page: number,
  perPage: number,
  sortBy: string,
  sortOrder: boolean,
) => {
  const response = await apiClient.get(
    `/admin/user/spam_unvisible?${params}&page=${page}&perPage=${perPage}&sortBy=${sortBy}&sortOrder=${sortOrder}`,
    {},
  )

  if (response.status === 200) {
    return response.data
  } else {
    throw new Error("Find User failed")
  }
}
