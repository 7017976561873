import React from "react"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { useEffect, useState } from "react"
import { TAapprovedUser } from "../services/userAPI"
import { WaitingApprovalUserData } from "../types/waitingApprovalUserData"
import { DataTable, DataTableSortStatus } from "mantine-datatable"
import sortBy from "lodash/sortBy"
import { setPageTitle } from "../redux/store/themeConfigSlice"
import {
  Filters,
  FilterValue,
  FilterType,
  CountryFilterValue,
} from "../types/waitingApprovalUserData"
// import DownloadPdfButton from '../components/DownloadPdfButton';
import DownloadCSVButton from "../components/DownloadCSVButton"
import KeywordData from "../JSON/KEYWORDS.json"
import CountryData from "../JSON/COUNTRIES.json"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faVenus, faMars, faEye } from "@fortawesome/free-solid-svg-icons"
import {
  selectApprovedUserFilters,
  setApprovedUserFilters,
} from "../redux/store/approvedUserFilterSlice"

const phoneNumberFixer = (phoneNumber: string) => {
  const fixedPhoneNumber = phoneNumber.slice(0, 13)
  return fixedPhoneNumber
}

const instaAverageLikeFixer = (instaAverageLike: number) => {
  if (!instaAverageLike) {
    return 0
  }
  const roundedNumber = Math.round(instaAverageLike * 10) / 10
  return roundedNumber
}

const tiktokAverageLikeFixer = (tiktokAverageLike: number) => {
  if (!tiktokAverageLike) {
    return 0
  }
  const roundedNumber = Math.round(tiktokAverageLike * 10) / 10
  return roundedNumber
}

const engagementRateFixer = (engagementRate: number) => {
  if (!engagementRate) {
    return 0
  }
  const roundedNumber = Math.round(engagementRate * 1000) / 1000
  return roundedNumber
}

const tiktokFollowersFixer = (tiktokEngagementRate: number) => {
  if (!tiktokEngagementRate) {
    return 0
  }
  return tiktokEngagementRate
}

const fetchData = async (
  params: any,
  page: number,
  perPage: number,
  sortBy: string,
  sortOrder: boolean,
) => {
  try {
    const response = await TAapprovedUser(params, page, perPage, sortBy, sortOrder)
    if (response && Array.isArray(response.users)) {
      const totalPages = response.totalPages
      const data = response.users.map((item: any, index: any) => {
        return {
          id: index + 1,
          _id: item._id,
          name: item.name,
          email: item.email,
          age: item.age,
          city: item.city,
          country: item.country,
          phone: phoneNumberFixer(item?.phone),
          gender: item.gender,
          profile_complete: item.profile_complete,
          insta_full_name: item.insta?.full_name,
          insta_username: item.insta?.username,
          insta_post_number: item.insta?.post_number,
          insta_followers: item.insta?.followers,
          insta_following: item.insta?.following,
          insta_keywords: item.insta?.keywords,
          insta_average_like: instaAverageLikeFixer(item.insta?.average_like),
          tiktok_username: item.tiktok?.username,
          tiktok_followers: tiktokFollowersFixer(item.tiktok?.followers),
          tiktok_videos: item.tiktok?.videos,
          tiktok_average_like: tiktokAverageLikeFixer(item.tiktok?.tiktok_average_like),
          tiktok_engagement_rate: engagementRateFixer(item.tiktok?.tiktok_engagement_rate),
          tiktok_hearts: tiktokFollowersFixer(item.tiktok?.hearts),
        }
      })
      return { data, totalPages }
    }
  } catch (error: any) {
    console.log("error", error)
    throw new Error(error)
  }
}

const ApprovedUsers = () => {
  const approvedUserFilters = useSelector(selectApprovedUserFilters)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(setPageTitle("Range Search Table"))
    const initialFilters = approvedUserFilters
    if (
      Object.keys(initialFilters).length > 0 &&
      JSON.stringify(initialFilters) !== JSON.stringify(defaultState)
    ) {
      setFilterss(initialFilters)
    }
  }, [dispatch])

  const PAGE_SIZES = [10, 20, 30, 50, 100]
  const [userData, setUserData] = useState([] as WaitingApprovalUserData[])
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(PAGE_SIZES[2])
  const [totalPages, setTotalPages] = useState(0)
  const [initialRecords, setInitialRecords] = useState(sortBy(userData, "id"))
  const [sortStatus, setSortStatus] = useState<DataTableSortStatus>({
    columnAccessor: "id",
    direction: "asc",
  })
  const [error, setError] = useState<string | null>(null)
  const [loading, setLoading] = useState(false)
  const [country, setCountry] = useState("")
  const [keywords, setKeywords] = useState("")
  const [autofillCountries, setAutofillCountries] = useState<string[]>([])
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [sortByColumn, setSortByColumn] = useState("")
  const [sortOrder, setSortOrder] = useState(false)
  const countries = CountryData.countries

  const defaultState: Filters = {
    age: { min: "", max: "" },
    followers: { min: "", max: "" },
    average_like: { min: "", max: "" },
    tiktok_followers: { min: "", max: "" },
    tiktok_average_like: { min: "", max: "" },
    tiktok_engagement_rate: { min: "", max: "" },
    country: { value: "" },
    gender: "",
    keywords: [],
  }

  const [filters, setFilterss] = useState<Filters>(defaultState)

  const setFilter = (key: keyof Filters, type: FilterType, value: string | string[] | "") => {
    if (key === "keywords") {
      setFilterss((prev) => ({ ...prev, [key]: value as string[] }))
      dispatch(setApprovedUserFilters({ ...filters }))
    } else if (key === "country") {
      setFilterss((prev) => ({ ...prev, [key]: { ...prev[key], [type]: value as string } }))
      dispatch(setApprovedUserFilters({ ...filters }))
    } else if (key === "gender") {
      setFilterss((prev) => ({ ...prev, [key]: value as "male" | "female" | "" }))
      dispatch(setApprovedUserFilters({ ...filters }))
    } else {
      setFilterss((prev) => ({ ...prev, [key]: { ...prev[key], [type]: value as string } }))
      dispatch(setApprovedUserFilters({ ...filters }))
    }
  }

  useEffect(() => {
    dispatch(setApprovedUserFilters(filters))
  }, [filters, dispatch])

  const handleFetchData = async () => {
    setLoading(true)
    dispatch(setApprovedUserFilters(filters))
    const flattenFilters = Object.entries(filters).reduce((acc, [key, filter]) => {
      if (key === "keywords") {
        console.log()
      } else if (key === "country") {
        acc[key] = (filter as CountryFilterValue).value
      } else {
        const { min, max } = filter as FilterValue
        if (min) {
          acc[`min_${key}`] = min
        }
        if (max) {
          acc[`max_${key}`] = max
        }
      }

      return acc
    }, {} as { [key: string]: string })
    const params = new URLSearchParams(flattenFilters)

    const keywords = (filters.keywords as string[]).map(
      (keyword) => keyword.charAt(0).toUpperCase() + keyword.slice(1),
    )
    keywords.forEach((keywords) => {
      params.append("keywords", keywords)
    })

    try {
      const data: any = await fetchData(params, page, pageSize, sortByColumn, sortOrder)
      if (data !== undefined) {
        setUserData(data.data)
        setInitialRecords(data.data)
        setTotalPages(data.totalPages)
      } else {
        setError("No data found")
      }
      setLoading(false)
    } catch (error) {
      setError("No data found")
    }
  }

  useEffect(() => {
    handleFetchData()
  }, [page, pageSize, sortByColumn, sortOrder])

  const handleSorting = (sortyByColumn: string) => {
    switch (sortyByColumn) {
      case "insta_post_number":
        setSortByColumn("insta.post_number")
        break
      case "insta_followers":
        setSortByColumn("insta.followers")
        break
      case "insta_average_like":
        setSortByColumn("insta.average_like")
        break
      case "tiktok_followers":
        setSortByColumn("tiktok.followers")
        break
      case "tiktok_average_like":
        setSortByColumn("tiktok.tiktok_average_like")
        break
      case "tiktok_engagement_rate":
        setSortByColumn("tiktok.tiktok_engagement_rate")
        break
      case "tiktok_hearts":
        setSortByColumn("tiktok.hearts")
        break
      default:
        setSortByColumn(sortyByColumn)
        break
    }
    setSortOrder((prevOrder) => !prevOrder)
  }

  const filterKeys: (keyof Filters)[] = [
    "age",
    "followers",
    "average_like",
    "tiktok_followers",
    "tiktok_average_like",
    "tiktok_engagement_rate",
    "country",
    "keywords",
  ]

  const formatKey = (key: string) => {
    switch (key) {
      case "age":
        return "Age"
      case "followers":
        return "Followers"
      case "average_like":
        return "Average Like"
      case "tiktok_followers":
        return "TikTok Followers"
      case "tiktok_average_like":
        return "TikTok Average Like"
      case "tiktok_engagement_rate":
        return "TikTok Engagement Rate"
      case "country":
        return "Country"
      case "keywords":
        return "Keywords"
      default:
        return key
    }
  }

  const renderBrandId = (record: any, index: number) => {
    const itemsPerPage = page * pageSize
    const recordIndex = itemsPerPage + index
    const brandId = recordIndex - pageSize + 1
    return <div>{brandId}</div>
  }

  const handleInputChangeCountry = (e: any) => {
    const value = e.target.value
    setFilter("country", "value", value)
    setCountry(value)

    if (value.length > 0) {
      const filteredCountries = countries
        .filter((country) => country.name.toLowerCase().startsWith(value.toLowerCase()))
        .map((country) => country.name)
      setAutofillCountries(filteredCountries)
    } else {
      setAutofillCountries([])
    }
  }

  const handleCountrySuggestionClick = (key: any, selectedCountry: any) => {
    setFilter(key, "value", selectedCountry)
    setCountry(selectedCountry)
    setAutofillCountries([])
  }

  const handleInputChange = (e: any) => {
    const inputKeywords = e.target.value
      .split(" ")
      .map((word: any) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ")
    setKeywords(inputKeywords)

    if (inputKeywords.length === 0) {
      setIsDropdownOpen(false)
      setFilter("keywords", "value", [])
    } else {
      setIsDropdownOpen(true)
    }
  }

  const autoCompleteKeywords = keywords.split(",").map((keyword) => keyword.trim())

  const lastKeyword = autoCompleteKeywords[autoCompleteKeywords.length - 1]

  const uniqueKeywords = [...new Set(KeywordData.keywords)]
  const filteredKeywords = uniqueKeywords
    .filter((keyword: string) => keyword.toLowerCase().startsWith(lastKeyword.toLowerCase()))
    .slice(0, 4)

  const autoCompleteKeyword: string[] = autoCompleteKeywords.length === 0 ? [] : filteredKeywords

  useEffect(() => {
    const handleClick = () => {
      setIsDropdownOpen(false)
    }
    document.addEventListener("click", handleClick)
    return () => {
      document.removeEventListener("click", handleClick)
    }
  }, [])

  return (
    <div className="panel">
      <div className=" flex md:items-center md:flex-row flex-col gap-5">
        <div className="md:flex md:flex-row w-full">
          {filterKeys.map((key) => {
            if (key !== "country" && key !== "keywords" && key !== "gender") {
              return (
                <div key={key} className="md:flex md:flex-col flex-1 mb-1 mr-2">
                  <h2 className="text-sm font-bold mb-2 ml-2">{formatKey(key)}</h2>
                  <input
                    type="text"
                    value={filters[key].min}
                    onChange={(e) => {
                      setFilter(key, "min", e.target.value)
                    }}
                    className="form-input w-full mb-2"
                    placeholder={`min. ${key}`}
                  />

                  <input
                    type="text"
                    value={filters[key].max}
                    onChange={(e) => {
                      setFilter(key, "max", e.target.value)
                    }}
                    className="form-input w-full"
                    placeholder={`max. ${key}`}
                  />
                </div>
              )
            }
          })}
        </div>
      </div>
      <div className="flex w-full justify-between flex-end">
        <div className="flex flex-row w-1/3 items-center">
          <div className="md:flex md:flex-row w-3/4">
            {filterKeys.map((key) => {
              if (key === "country") {
                return (
                  <div key={key} className="md:flex md:flex-col flex-1 mb-4">
                    <div>
                      <h2 className="text-sm font-bold mb-1 mt-3 ml-2">Country Name</h2>
                      <input
                        type="text"
                        value={filters[key].value}
                        onChange={handleInputChangeCountry}
                        className="form-input w-full"
                        placeholder={"Country name"}
                      />
                    </div>
                    {autofillCountries.length > 0 && country.length > 0 && (
                      <ul
                        className="suggestion-list mt-20 ml-2"
                        style={{ position: "absolute", zIndex: 9999 }}
                      >
                        {[...new Set(autofillCountries)]
                          .slice(0, 5)
                          .map((autofillCountry, index) => (
                            <li
                              key={index}
                              className="bg-white p-2 text-black cursor-pointer hover:bg-gray-200"
                              onClick={() =>
                                handleCountrySuggestionClick("country", autofillCountry)
                              }
                            >
                              {autofillCountry}
                            </li>
                          ))}
                      </ul>
                    )}
                  </div>
                )
              } else if (key === "keywords") {
                return (
                  <div key={key} className="md:flex md:flex-col flex-1 mb-4 mt-3 ml-2">
                    <div>
                      <h2 className="text-sm font-bold ml-2 mb-1">Keywords</h2>
                      <input
                        type="text"
                        value={filters[key].join(",")}
                        onChange={(e) => {
                          const keywords = e.target.value.split(",").map((word) => {
                            const trimmedWord = word.trim()
                            return (
                              trimmedWord.charAt(0).toUpperCase() +
                              trimmedWord.slice(1).toLowerCase()
                            )
                          })
                          setFilter(key, "value", keywords)
                          handleInputChange(e)
                        }}
                        className="form-input w-full"
                        placeholder={"keyword1, keyword2.."}
                      />
                    </div>
                    {isDropdownOpen && keywords.length > 0 && (
                      <div>
                        <ul
                          className="suggestion-list"
                          style={{ position: "absolute", zIndex: 9999 }}
                        >
                          {autoCompleteKeyword.map((keyword, index) => (
                            <li
                              key={index}
                              className="bg-white p-2 text-black cursor-pointer hover:bg-gray-200"
                              onClick={() => {
                                const currentInput = filters[key].join(", ")
                                if (currentInput.includes(",")) {
                                  const parts = currentInput.split(",")
                                  parts[parts.length - 1] = keyword
                                  setFilter(
                                    key,
                                    "value",
                                    parts.map((part) => part.trim()),
                                  )
                                } else {
                                  setFilter(key, "value", [keyword])
                                }
                                setIsDropdownOpen(false)
                              }}
                            >
                              {keyword}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                )
              }
            })}
          </div>
        </div>

        <div className="flex flex-row justify-end text-center w-1/3 mb-6 mt-2 mr-2">
          <button
            className=" inline-flex items-center justify-center ml-2 px-2 py-2 mt-3 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 "
            onClick={() => handleFetchData()}
          >
            Search Users
          </button>
          {/* <DownloadPdfButton
            className=" inline-flex items-center justify-center px-2 py-2 mt-3 border border-transparent text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 "
            userData={initialRecords}
          /> */}
          <DownloadCSVButton
            className=" inline-flex items-center justify-center ml-2 px-2 py-2 mt-3 border border-transparent text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 "
            userData={initialRecords}
          />
        </div>
      </div>
      <div className="datatables">
        {loading ? (
          <div className="flex items-center justify-center h-40">
            <div className="animate-spin rounded-full h-10 w-10 border-t-4 border-pink-600"></div>
          </div>
        ) : (
          <DataTable
            highlightOnHover
            className="whitespace-nowrap table-hover"
            records={initialRecords}
            columns={[
              {
                accessor: "details",
                title: "Details",
                sortable: false,
                render: ({ _id }: any) => (
                  <Link to={`/user/find/${_id}`}>
                    <div className="text-center items-center">
                      <FontAwesomeIcon icon={faEye} style={{ color: "#005eff" }} />
                    </div>
                  </Link>
                ),
              },
              { accessor: "id", title: "Id", sortable: true, render: renderBrandId },
              {
                accessor: "firstName",
                title: "Name",
                sortable: true,
                render: ({ name }) => <div>{name}</div>,
              },
              { accessor: "email", title: "Email", sortable: true },
              { accessor: "age", title: "Age", sortable: true },
              {
                accessor: "gender",
                title: "Gender",
                sortable: false,
                render: ({ gender }) => (
                  <div className="text-center items-center">
                    {gender === "male" ? (
                      <FontAwesomeIcon icon={faMars} style={{ color: "#005eff" }} />
                    ) : (
                      <FontAwesomeIcon icon={faVenus} style={{ color: "#ff00dd" }} />
                    )}
                  </div>
                ),
              },
              { accessor: "country", title: "Country", sortable: true },
              { accessor: "insta_full_name", title: "FullName", sortable: true },
              {
                accessor: "insta_username",
                title: "Insta Username",
                sortable: true,
                render: ({ insta_username }: any) => (
                  <a
                    href={`https://www.instagram.com/${insta_username}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="text-center items-center">
                      <span>{insta_username}</span>
                    </div>
                  </a>
                ),
              },
              {
                accessor: "tiktok_username",
                title: "Tiktok Username",
                sortable: true,
                render: ({ tiktok_username }: any) => (
                  <a
                    href={`https://www.tiktok.com/@${tiktok_username}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="text-center items-center">
                      <span>{tiktok_username}</span>
                    </div>
                  </a>
                ),
              },
              { accessor: "insta_followers", title: "Insta Followers", sortable: true },
              { accessor: "insta_average_like", title: "Insta Average Like", sortable: true },
              { accessor: "insta_post_number", title: "Insta Post Number", sortable: true },
              { accessor: "tiktok_followers", title: "Tiktok Followers", sortable: true },
              { accessor: "tiktok_average_like", title: "Tiktok Average Like", sortable: true },
              {
                accessor: "tiktok_engagement_rate",
                title: "Tiktok Engagement Rate",
                sortable: true,
              },
              { accessor: "tiktok_hearts", title: "Tiktok Hearts", sortable: true },
            ]}
            totalRecords={totalPages * pageSize}
            recordsPerPage={pageSize}
            page={page}
            onPageChange={(p) => setPage(p)}
            recordsPerPageOptions={PAGE_SIZES}
            onRecordsPerPageChange={setPageSize}
            sortStatus={sortStatus}
            onSortStatusChange={(e) => handleSorting(e.columnAccessor)}
            minHeight={200}
            paginationText={({ from, to, totalRecords }) =>
              `Showing  ${from} to ${to} of ${totalRecords} entries`
            }
          />
        )}
      </div>
    </div>
  )
}

export default ApprovedUsers
